import React, { Component } from 'react';

import './App.scss';
// import {SectionContainer} from './viewcomponents/Bulma'
import reactPages from './pages-react'
import Ripples from 'react-ripples'
import AsideList from './Layout/AsideList'
import MainPageContent from './PageType/MainPageContent'
import { AppWrapper, Aside, PageMainContent, Footer, FooterLink, Cover } from './Layout/UiComponents'
import AsideHandle from './Layout/AsideHandle'
import useDraggable from './Layout/useDraggable'

import './FontAwesome'




export default function App() {
	const { onMouseDown: a_onMouseDown, state: a_state } = useDraggable({ x: 200 });

	return (
		<AppWrapper x={a_state.x}>
			<Aside>
				<AsideList pages={reactPages} />
				<AsideHandle onMouseDown={a_onMouseDown} {...a_state} />
				{ a_state.beingDragged && <Cover x={a_state.x}/> }
			</Aside>
			<PageMainContent>
				<MainPageContent pages={reactPages}/>
			</PageMainContent>
			<Footer>
				<FooterLink href="https://reactfirebase.com" target="_blank">Learn to build full-featured React apps</FooterLink>
			</Footer>
		</AppWrapper>
	);
}

			// <div style={{height:'100%'}}>
			// 	<Footer />
			// 	<div className="columns" style={{height:'100%'}}>
			// 		<div className="column" style={{maxWidth:200,height:'100%',paddingRight:0}}>
			// 			<AsideMenu pages={pages} />
			// 		</div>
			// 		<div className="column" style={{height:'100%',paddingLeft:0, backgroundColor:'#1d1f20'}}>
			// 			<Switch>{routes}</Switch>
			// 		</div>
			// 	</div>
			// </div>

