// https://codesandbox.io/s/pages-and-categories-d6wc3

const pages = [ /* REACT */
	{
		type: 'category',
		title: 'Basics',
		children: [
			// {   // old "Hello" page
			// 	type: 'codepen',
			// 	title: 'Hello',
			// 	to:'/',
			// 	hash:"f037f5f42c8961eb3994876050257c83"
			// },
			{
				type: 'codepen',
				title: 'Hello',
				to:'/',
				hash:"rNOBpPr"
			},
			{
				type: 'codepen',
				title: 'Function Components',
				to:'/function-components',
				hash:"RwWbQRR"
			},
			{
				type: 'codepen',
				title: 'Class Components',
				to:'/class-components',
				hash:"YzqaKmX"
			},
			{
				type: 'codepen',
				title: 'JSX',
				to:'/jsx',
				hash:"eYpYgKJ"
			},
			// {
			// 	type: 'codepen',
			// 	title: 'Composition',
			// 	to:'/composition',
			// 	hash:"666e7284494524d4d5c7914a7106ec91"
			// },
			// {
			// 	type: 'codepen',
			// 	title: 'Presentational',
			// 	to:'/presentational',
			// 	hash:"236d5069c4257f7ed18823915054bba8"
			// },
			{
				type: 'codepen',
				title: 'Props',
				to:'/props',
				hash:"eYZMErz"
			},
		],
	},
	// {
	// 	type: 'category',
	// 	title: 'Function Component',
	// 	children: [
	// 		{ 
	// 			type: '',
	// 			title: '--',
	// 			to:'/func',
	// 			hash:"f037f5f42c8961eb3994876050257c83"
	// 		},
			
	// 	],
	// },
	// {
	// 	type: 'category',
	// 	title: 'Class Component',
	// 	children: [
	// 		{ 
	// 			type: '',
	// 			title: '--',
	// 			to:'/class',
	// 			hash:"f037f5f42c8961eb3994876050257c83"
	// 		},
	// 	],
	// },
	// {
	// 	type: 'category',
	// 	title: 'JSX',
	// 	children: [
	// 		{ 
	// 			type: '',
	// 			title: 'DOM elements vs React components',
	// 			to:'/elements-vs-components',
	// 			hash:"f037f5f42c8961eb3994876050257c83"
	// 		},
			
	// 	],
	// },
	// {
	// 	title: 'Props',
	// 	type: 'category',
	// 	children: [

	// 	]
	// },
	{
		title: 'State',
		type: 'category',
		children: [
			{
				type: 'codepen',
				to: '/state',
				title: 'State',
				hash:"bGpvrOX"
			},
			{
				type: 'codepen',
				to: '/usestate',
				title: 'useState',
				hash: 'vYGRYZy'
			},
			{
				type: 'codepen',
				to: '/state-interval',
				title: 'State - interval',
				hash:"2dc8ae7493ced81e05713c8e0ac33dd6"
			},
		]
	},
	{
		title: 'Events',
		type: 'category',
		children: [
			{
				type: 'codepen',
				to: '/onclick', 
				title: 'onClick', 
				hash:"7476a3e210c5df07628fd6198ad8871c"
			},
			{
				type: 'codepen',
				to: '/preventdefault', 
				title: 'preventDefault', 
				hash:"4578988d5a9d83556092415d03eed4e2"
			},
		]
	},
	{
		title: 'Ref',
		type: 'category',
		children: [
			{
				type: 'codepen',
				to: '/ref', 
				title: 'ref', 
				hash:"PjWeLz"
			},
			{
				type: 'codepen',
				to: '/useref', 
				title: 'useRef hook', 
				hash:"QWNmqGE"
			},
		]
	},
	// {
	// 	title: 'Hooks',
	// 	type: 'category',
	// 	children: [

	// 	]
	// },
	{
		title: 'Conditionals',
		type: 'category',
		children: [
			{
				type: 'codepen',
				to: '/if',
				title: 'If',
				hash:"85fceffb0af5d74dc815d6d150b6480d"
			},
		]
	},
	{
		title: 'Loop',
		type: 'category',
		children: [
			{
				type: 'codepen',
				to: '/array',
				title: 'Array',
				hash:"VWPxgZ"
			},
			{
				type: 'codepen',
				to: '/list-components',
				title: 'List Components',
				hash:"QgdJXb"
			},
			{
				type: 'codepen',
				to: '/filter',
				title: 'Filter',
				hash:"gRgZpJ"
			},
		]
	},


	{
		title: 'Forms',
		type: 'category',
		children: [
			{
				type: 'codepen',
				to: '/select',
				title: 'Select',
				hash:"rNedGGp"
			},
			{
				type: 'codepen',
				to: '/radio',
				title: 'Radio',
				hash:"xxVWXjy"
			},
			{
				type: 'codepen',
				to: '/checkbox',
				title: 'Checkbox',
				hash:"XWdEeyB"
			},
			{
				type: 'codepen',
				to: '/file',
				title: 'File Input',
				hash:"eYZMyPd"
			},
			{
				type: 'codepen',
				to: '/tags',
				title: 'Tags',
				hash:"bGpvKBd"
			},
			{
				type: 'codepen',
				to: '/autocomplete',
				title: 'Autocomplete',
				hash:"NWNzjJG"
			},
		]
	},



	{
		title: 'Lifecycle Methods',
		type: 'category',
		children: [
			{
				type: 'codepen',
				to: '/lifecycle-methods',
				title: 'Lifecycle Methods',
				hash:"bf6fc93441cab7fcb1658b2c1b86184d"
			},
			{
				type: 'codepen',
				to: '/lifecycle-hooks',
				title: 'Lifecycle Hooks',
				hash:"dyMePYN"
			},
		]
	},
	{
		title: 'Portal',
		type: 'category',
		children: [
			{
				type: 'codepen',
				to: '/modal',
				title: 'Modal',
				hash:"zYqLOEN"
			},

		]
	},
	// {
	// 	title: 'Composition', 
	// 	type: 'category',
	// 	children: [
	// 		{
	// 			type: 'codepen',
	// 			to: '/composition-callbacks',
	// 			title: 'Composition & Callbacks',
	// 			hash:"76f8dc5c5187e8e7f2cf6ebbb84b2507"
	// 		},
	// 	]
	// },
	{
		title: 'Fetch',
		type: 'category',
		children: [
			{
				type: 'codepen',
				to: '/fetch',
				title: 'Fetch',
				hash:"ZybQGY"
			},
		]
	},
	{
		title: 'Hooks',
		type: 'category',
		children: [
			{
				type: 'codepen',
				to: '/hooks-window-size',
				title: 'Watching window size with hooks',
				hash:"BayNxOo"
			},
		]
	},
	{
		title: 'Redux',
		type: 'category',
		children: [
			// {
			// 	type: '',
			// 	to: '/tpage',
			// 	title: 'Tpage'
			// },
			{
				type: 'codepen',
				to: '/redux',
				title: 'Redux State',
				hash:"WNwzXod"
			},
			{
				type: 'codepen',
				to: '/react-redux',
				title: 'React-Redux',
				hash:"c83d8c42d26468b41bf4e6818bf6c3a5"
			},
			{
				type: 'codepen',
				to: '/combinereducers',
				title: 'combineReducers',
				hash:"1680b5868b12188881e6fb796ecf9242"
			},
			// {
			// 	type: 'codesandbox',
			// 	to: '/react-redux-reselect',
			// 	title: 'React Redux Reselect',
			// 	hash:"quick-react-redux-reselect-sfcx4"
			// },
		]
	},
	{
		title: 'Drag Element',
		type: 'category',
		children: [
			{
				type: 'codesandbox',
				to: '/move-element',
				title: 'Move Element',
				hash: "react-moving-an-element-iywqc"
			},
		]
	},

];

export default pages