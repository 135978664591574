import React from 'react';
import { NavLink } from 'react-router-dom'


const AsideMenu = ({pages}) => {
	return (
		<aside className="menu" style={{height:'100%'}}>
			{pages.map( (item,i) => {
				if( typeof item === 'string') { // category
					return <p key={i} className="menu-label">{item}</p>
				}
				return <ul key={i} className="menu-list">{item.map( (link,ii) => {
					return (
						<li key={`$(i)_${ii}`}>
							<NavLink exact activeClassName="is-active" to={link.to}>
								<span className="icon is-small" style={{paddingRight:10}}><i className="fa fa-codepen"></i></span>
								{link.title}
							</NavLink>
						</li>
						)
				})}</ul>
			})}
		</aside>
	)
}

export default AsideMenu