import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import './viewcomponents/bulma.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Codepen from './PageType/Codepen'
import AsideMenu from './Layout/AsideMenu'
// import reactPages from './pages-react'
// import vuejs from './pages-vuejs'
// import backbone from './pages-backbone'

/*
	TODO: Share with `react-share`
	https://www.npmjs.com/package/react-share
*/


// function getPages(){
// 	if(window.location.hostname.indexOf('react')>-1) return react
// 	// if(window.location.hostname.indexOf('vuejs')>-1) return vuejs
// 	// if(window.location.hostname.indexOf('backbone')>-1) return backbone
// 	return react // <-- DEFAULT SITE for localhost
// }
// const pages = getPages();
// const pages = react;


const Content = ({link}) => {
	if(link.hash) {
		return <Codepen user="yurio" hash={link.hash} tab="js,result" />;
	}
	return <div>NO CONTENT</div>
}


// const Home = () => {
// 	return <Redirect key={i++} exact from='/' to="/class" />
// 	// console.log('HOME');
// 	// return (<div>WELCOME</div> )
// }




// const routes = [];
// let i=0;
// pages.forEach( row => {
// 	if( typeof row === 'string' ) return;
// 	row.forEach( link => {
// 		routes.push(
// 			<Route exact path={link.to} key={i++} component={()=> <Content link={link} /> } />
// 		)
// 	})
// });

// routes.push( <Route exact path='/' key={i++} component={ ()=><Home />}  />);
// routes.push( <Redirect key={i++} exact from='/' to="/class" /> );


const Footer = () => {
	return (
		<div className="bottommessage">
			by <a target="_blank" rel="noopener noreferrer" href="https://yur.io">yur.io</a>
			&nbsp; — &nbsp;
			<a target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=React.guide%20is%20awesome%20for%20learning%20ReactJS%20through%20examples&url=https%3A%2F%2Freact.guide&hashtags=reactjs">
				<span className="icon is-small" style={{paddingRight:5}}>
					<i className="fa fa-twitter"></i>
				</span>
			Tweet</a>
			&nbsp; — &nbsp;
			<a target="_blank" rel="noopener noreferrer" href="https://www.codementor.io/yurio">Connect with author</a>
		</div>
	)
}


ReactDOM.render(
	<Router>
		<Route component={App} />
	</Router>
	, document.getElementById('root'));


// eslint-disable-next-line
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) })(window,document,'script','https://www.google-analytics.com/analytics.js','ga'); ga('create', 'UA-17655032-18', 'auto'); ga('send', 'pageview');
