import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Codepen extends Component {
	render(){
		// const src  = `//codepen.io/${this.props.user}/embed/${this.props.hash}/?height=${this.props.height}&theme-id=${this.props.theme}&default-tab=${this.props.tab}`;
		const src = `https://codepen.io/${this.props.user}/embed/${this.props.hash}/?theme-id=30087&default-tab=${this.props.tab}&embed-version=2`
		// const user = `http://codepen.io/${this.props.user}`;
		// const pen  = `${user}/pen/${this.props.hash}/`;
		const pen = `https://codepen.io/${this.props.user}/pen/${this.props.hash}/`;

		// return <div style={{background:'blue',height:'100%'}}></div>

		return (
			<iframe 
				id="theiframe"
				scrolling='no' 
				title='React filter' 
				src={src}
				frameBorder='no' 
				allowTransparency='true' 
				allowFullScreen='true' 
				>See the Pen <a href={pen}>React filter</a> by {this.props.user} (<a href='https://codepen.io/{this.props.user}'>@{this.props.user}</a>) on <a href='https://codepen.io'>CodePen</a>.
			</iframe>
		)
	}
}
Codepen.propTypes = {
	user: PropTypes.string.isRequired,
	hash: PropTypes.string.isRequired,
	// tab: React.PropTypes.oneOf(['html', 'css', 'result', 'js']),
	// theme: React.PropTypes.string
}


export default Codepen