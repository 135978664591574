// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from 'styled-components'

export const AppWrapper = styled.div`
    display: grid;
    grid-template-columns: ${props => props.x || 0}px 1fr;
    grid-template-rows: 1fr 24px;
    grid-template-areas: "Aside Content Content Content" "Aside Content Content Content" "Aside Footer Footer Footer";
    height: 100vh;
`;

export const Aside = styled.div`
    grid-area: Aside;
    position: relative;
`;


export const PageMainContent = styled.div`
    grid-area: Content;
`;

export const Footer = styled.div`
    grid-area: Footer;
    padding: 4px 12px 6px 8px;
    font-size: 0.7em;
    text-align: right;
`;


export const FooterLink = styled.a`
    color: var(--main-bg-text-color-highlight);
    cursor: pointer;
    user-select: none;
    display: inline-block;
    text-decoration: none;
    &:hover {
        color: #cecece;
        text-shadow: rgba(255,255,255,0.4) 0px 0px 6px;
    }
`;


export const Cover = styled.div`
    position: fixed;
    top:0;
    right: 0;
    bottom:0;
    left: 0;
    background: rgba(0,0,0,0.01);
`


