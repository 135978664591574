import { library } from '@fortawesome/fontawesome-svg-core'


// import all brand icons
// import { fab } from '@fortawesome/free-brands-svg-icons'
import { faFacebook, faCodepen } from '@fortawesome/free-brands-svg-icons'

import { faSearch, faFileAlt } from '@fortawesome/pro-regular-svg-icons'

// import some icons
import { faCheckSquare, faCoffee, faSquare, faCheck, faEnvelope, faBell } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleUp } from '@fortawesome/pro-light-svg-icons'

console.log('faArrowAltCircleUp:', faArrowAltCircleUp)

library.add(
    faCodepen
    ,faFileAlt
    // ,faFacebook, faBell, faSearch, faCheckSquare, faCoffee, faArrowAltCircleUp, faSquare, faCheck, faEnvelope
    );