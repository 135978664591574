import React from 'react';

export default function Codesandbox({ id }) {
		const src = `https://codesandbox.io/embed/${id}?fontsize=14&hidenavigation=1&theme=dark`

		return (
			<iframe 
				id="theiframe"
				scrolling='no' 
				src={src}
				frameBorder='no' 
				allowTransparency='true' 
				allowFullScreen='true' 
                allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb"
                sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"
				></iframe>
		)
}