// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from 'styled-components'


// export const AsideHandle = styled.div`
//     position: absolute;
//     top:0;
//     right: 0;
//     width: 16px;
//     height: 100vh;
//     background-color:#070708;
// `;



// function AsideHandle() {
//     return (
//         <div></div>
//     )
// }



export const AsideHandle = styled.div`
    position: absolute;
    top:0;
    right: 0;
    width: 16px;
    height: 100vh;
    background-color:#070708;
    cursor: ew-resize;

    &::after {
        content: "";
        display: flex;
        position: absolute;
        width: 4px;
        height: 41px;
        top: calc(50% - 20px);
        margin-left: 6px;
        opacity: 1;
        background: #444444;
        border-radius: 50px;
    }
`;


export default AsideHandle;