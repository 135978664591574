import React, { Fragment } from 'react';
import reactPages from '../pages-react'
import {
    useLocation
} from "react-router-dom";
import Codepen from './Codepen'
import Codesandbox from './Codesandbox'



const pagesBySlug = {};
reactPages.forEach(category => {
    category.children.forEach(page => {
        pagesBySlug[page.to] = page;
    });
});
  
const MainPageContent = ({ pages }) => {
    let { pathname } = useLocation();

    const page = pagesBySlug[pathname];
    if(!page) {
        return (
            <div>Page not found</div>
        )
    }
    if(page.type==='codepen'){
        return (
            <Codepen user="yurio" hash={page.hash} tab="js,result" />
        )
    }
    if(page.type==='codesandbox'){
        return (
            <Codesandbox id={page.hash} />
        )
    }
    return null;
}

export default MainPageContent;