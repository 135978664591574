import React from 'react';
import { NavLink } from 'react-router-dom'
import Ripples from 'react-ripples'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {ReactComponent as CodesandboxSvg} from '../icons/code-sandbox.svg'

const Category = styled.div`
`;

const CategoryTitle = styled.div`
    color: var(--main-bg-text-color);
    font-size: 0.8em;
    padding: 16px 8px 6px 16px;
    user-select: none;
`;
const PagesContainer = styled.div`
`;

const CodesandboxIcon = styled(CodesandboxSvg)`
    max-width: 16px;
`;
const PageLink = styled(NavLink)`
    background-color: var(--nav-btn-bg-color);
    display: block;
    padding: 6px 8px;
    margin-right: 16px;
    line-height: 1.34;
    text-decoration: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: var(--nav-btn-border-color);
    user-select: none;

    color: var(--nav-btn-main-text-color);
    transition: background-color 0.1s, border-color 0.1s, color 0.1s;

    &:hover {
        background-color: var(--nav-btn-hover-bg-color);
    }
    &.active {
        background-color: var(--nav-btn-active-bg-color);
        color: var(--nav-btn-active-text-color);
    }
    &:last-child {
        border-bottom-width: 0;
    }
`

const IconImg = styled.img`
    max-width: 100%;
`

const IconWrapper = styled.span`
    max-width: 16px;
    max-height: 16px;
    margin: 0 8px 5px 0;
    display: inline-block;
    vertical-align: bottom;
`;


const AsideContainer = styled.div`
    overflow: auto;
    padding-bottom: 100px;
    height: 100vh;
    max-height: 100vh;
`;


const iconForPage = page => {
    switch(page.type){
        case 'codepen':
            return <IconWrapper><FontAwesomeIcon icon={['fab', "codepen"]} /></IconWrapper>
            case 'codesandbox':
                return <IconWrapper><CodesandboxIcon /></IconWrapper>
                // return <IconImg src={codesandboxIcon} />
            default:
                return <IconWrapper><FontAwesomeIcon icon={['far', 'file-alt']} /></IconWrapper>

    }
}

const AsideMenu = ({ pages }) => {
	return (
		<AsideContainer>
			{pages.map( (category,i) => {
                return (
                    <Category key={i}>
                        <CategoryTitle>{category.title}</CategoryTitle>
                        <PagesContainer>
                        {category.children.map((page,ii) => {
                            return (
                                <PageLink exact to={page.to}>
                                    {iconForPage(page)}
                                    {page.title}
                                </PageLink>
                            )
                        })}
                        </PagesContainer>
                    </Category>
                )
			})}
		</AsideContainer>
	)
}

				// if( typeof item === 'string') { // category
				// 	return <p key={i} className="menu-label">{item}</p>
				// }
				// return <ul key={i} className="menu-list">{item.map( (link,ii) => {
				// 	return (
				// 		<li key={`$(i)_${ii}`}>
				// 			<NavLink exact activeClassName="is-active" to={link.to}>
				// 				<span className="icon is-small" style={{paddingRight:10}}><i className="fa fa-codepen"></i></span>
				// 				{link.title}
				// 			</NavLink>
				// 		</li>
				// 		)
				// })}</ul>

                export default AsideMenu