import React, { useReducer, useEffect } from "react";
import styled from "styled-components";

const initState = {
	x:0,
	y:0,
	initialX: 0, // element's position before drag started
	initialY: 0,
	initialCursorX: 0, // cursor's position before drag started
	initialCursorY: 0,
	beingDragged: false,
  };
  
  const reducer = (state, action) => {
	switch(action.type){
	  case 'START_DRAG':
		return {
		  ...state,
		  initialX: state.x,
		  initialY: state.y,
		  initialCursorX: action.cursorX,
		  initialCursorY: action.cursorY,
		  beingDragged: true,
		};
	  case 'END_DRAG':
		return {
		  ...state,
		  beingDragged: false,
		};
	  case 'SET_ITEM_POSITION': 
		return ( state.initialCursorX && state.initialX ) ?
		  { ...state,
			x: action.x - state.initialCursorX + state.initialX,
			y: action.y - state.initialCursorY + state.initialY,
		  } : 
		  { ...state,
			x: action.x,
			y: action.y,
		  };
	  default:
		return state;
	}
};

export default function useDraggable(initialValues){
	const [state, dispatch] = useReducer(reducer, {...initState, ...initialValues});
	const {x,y, initialX, initialY, initialCursorX, initialCursorY, beingDragged} = state;

	useEffect(()=>{
		if(beingDragged){
		const handleDrag = e => { // drag handler
			dispatch({ type: 'SET_ITEM_POSITION', x: e.pageX, y: e.pageY });
		};
		const releaseDrag = e => { // mouse up handler
			dispatch({type:'END_DRAG'});
		};
		window.document.addEventListener('mousemove', handleDrag, false);
		window.document.addEventListener('mouseup', releaseDrag, false);
		return () => {
			window.document.removeEventListener('mousemove', handleDrag, false);
			window.document.removeEventListener('mouseup', releaseDrag, false);
		};
		}
	},[beingDragged]);

	const onMouseDown = e => {
		e.stopPropagation();
		dispatch({type:'START_DRAG', cursorX: e.pageX, cursorY: e.pageY});
	}
	return { onMouseDown, state, dispatch };
}

